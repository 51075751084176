const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://gsuxd.xyz',
  title: '',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Jesus Polanco',
  role: 'FullStack Engineer',
  description:
    '"damn."',
  resume: '',
  social: {
    facebook: 'https://facebook.com/gsuTesfaye',
    github: 'https://github.com/gsuxd',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Windhand',
    description:
      'Mauqetación de página web responsive a partir de diseño',
    stack: ['CSS', 'React'],
    sourceCode: 'https://github.com/gsuxd/Winhand-vite',
    livePreview: 'https://gsuxd.github.io/Winhand-vite',
  },
  {
    name: 'Notes App',
    description:
      'WebApp de notas FullStack sin estilos',
    stack: ['React', 'Express', 'NodeJS', 'MongoDB'],
    sourceCode: 'https://github.com/gsuxd/Notes-XD',
    livePreview: 'https://gsuxd.github.io/Notes-XD/',
  },
  {
    name: 'componente tarjeta de visualizacion de estados',
    description:
      'Componente de tarjeta responsive',
    stack: ['CSS', 'Typescript', 'React'],
    sourceCode: 'https://github.com/gsuxd/stats-preview-card-component/',
    livePreview: 'https://stats-preview-card-component-hazel.vercel.app/',
  },
  {
    name: 'Sumario de ordenes',
    description:
      'Componente de Sumario de ordenes responsive',
    stack: ['CSS', 'Typescript', 'React'],
    sourceCode: 'https://github.com/gsuxd/Order-summary',
    livePreview: 'https://order-summary1415.netlify.app/',
  },
  {
    name: 'Keegochat',
    description: 'App de chat corporativo dirigido a la atención al cliente',
    stack: ['Typescript', 'React', 'React Native'],
    livePreview: 'https://play.google.com/store/apps/details?id=com.keego.keegochat'
  },
  {
    name: 'Choix 57',
    description: 'Maquetación a partir de diseño para plataforma de apuestas mexicanas',
    stack: ['HTML', 'CSS'],
    sourceCode: 'https://github.com/gsuxd/choix57_page',
    livePreview: 'https://gsuxd.github.io/choix57_page/',
  },
  {
    name: 'AForce',
    description: 'Maquetación a partir de diseño para panel administrativo',
    stack: ['React', 'CSS'],
    sourceCode: 'https://github.com/gsuxd/aforce',
    livePreview: 'https://aforce.vercel.app/login'
  },
  {
    name: 'Blogr Landing Page',
    description: 'Maquetación de landing page a partir de diseño',
    stack: ['HTML', 'CSS', 'Javascript'],
    sourceCode: 'https://github.com/gsuxd/blogr-landing-page',
    livePreview: 'https://blogr-landing-page-ten-sandy.vercel.app/',
  },
  {
    name: 'Fylo Landing Page',
    description: 'Maquetación de landing page a partir de diseño',
    stack: ['Svelte', 'CSS'],
    sourceCode: 'https://github.com/gsuxd/fylo-landing-page',
    livePreview: 'https://fylo-landing-page-ecru.vercel.app/',
  }
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Git',
  'Flutter',
  'Dart',
  'React Native',
  'NodeJS',
  'Express',
  'NestJS',
  'Python',
  'Svelte',
  'NextJS'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'jesusmanuelpolancogarcia51@gmail.com',
}

export { header, about, projects, skills, contact }
